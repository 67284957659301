import "./Process.css"
import image from "../../../Assets/Bg/web.webp"
import arrow from "../../../Assets/Common/arrow.webp"
import arrow2 from "../../../Assets/Common/arrow-flip.webp"
import arrowdown from "../../../Assets/Common/arrow-down.webp"
import { useEffect, useState } from "react"
import { motion } from "framer-motion"

export default function Process() {
    const [mobileScreen, setMobileScreen] = useState(false)

    useEffect(() => {
        // Function to handle resize and set screen width and size accordingly
        const handleResize = () => {
            const width = window.innerWidth;
            if(width < 768) {
                setMobileScreen(true)
            } else {
                setMobileScreen(false)
            }
        };
        
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        
        // Initial check in case window width has already changed
        handleResize();
        
        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <section id="#WhatWeDo" className="WhatWeDo container1">
            {/* <div className="textContainer">
                <p>Foucs on helping your Brand grow<br/>
                and move Forward</p><hr/>
            </div> */}
            <div className="titleContainer">
                <div className="title">
                    <img src={image} alt="" />
                    <h1>
                        Process
                    </h1>
                </div>
                {/* <div className="title">
                    <h1>
                        <span>For Your</span> Business
                    </h1>
                    <a href="#services"><button>What We Do?</button></a>
                </div> */}
            </div>
            <div className="listContainer">
                <div className="box">
                    <h1>Planning & Research</h1>
                    <ul>
                        <li>Define the purpose, goals, and scope of the website.</li>
                        <li>Conduct audience and competitor research.</li>
                        <li>Create a project timeline and sitemap outlining the structure.</li>
                        <li>Gather content and assets needed for the website.</li>
                    </ul>
                    {/* <button>Go</button> */}
                </div>
                <motion.img 
                    initial={{
                        rotate: -180,
                        opacity: 0,
                    }}
                    whileInView={{
                        rotate: 0,
                        opacity: 1,
                    }}
                    transition={{
                        duration: 2,
                        ease: "easeInOut" 
                    }}
                    viewport={{ once: false }}
                    src={mobileScreen ? arrowdown : arrow} alt="" className={mobileScreen ? "arrow-down" : "arrow"}/>
                <div className="box">
                    <h1>Design</h1>
                    <ul>
                        <li>Create wireframes to map out layouts and user journeys.</li>
                        <li>Design mockups for pages, focusing on branding, UI, and UX.</li>
                        <li>Select color schemes, typography, and imagery.</li>
                        <li>Ensure the design is responsive for various devices.</li>
                        <li>Review and approve the designs before development.</li>
                    </ul>
                    {/* <button>Go</button> */}
                </div>
                <motion.img 
                    initial={{
                        rotate: 180,
                        opacity: 0,
                    }}
                    whileInView={{
                        rotate: 0,
                        opacity: 1,
                    }}
                    transition={{
                        duration: 2,
                        ease: "easeInOut" 
                    }}
                    viewport={{ once: false }}
                    src={mobileScreen ? arrowdown : arrow2} alt="" className={mobileScreen ? "arrow-down" : "arrow"}/>
                <div className="box">
                    <h1>Development</h1>
                    <ul>
                        <li>Code the front-end (HTML, CSS, JavaScript) and back-end (databases, APIs).</li>
                        <li>Implement CMS if needed for easy content management.</li>
                        <li>Optimize website performance (loading speed, SEO).</li>
                        <li>Ensure compatibility across browsers and devices.</li>
                    </ul>
                    {/* <button>Go</button> */}
                </div>
                {/* <hr/> */}
                <motion.img 
                    initial={{
                        rotate: -180,
                        opacity: 0,
                    }}
                    whileInView={{
                        rotate: 0,
                        opacity: 1,
                    }}
                    transition={{
                        duration: 2,
                        ease: "easeInOut" 
                    }}
                    viewport={{ once: false }}
                    src={mobileScreen ? arrowdown : arrow} alt="" className={mobileScreen ? "arrow-down" : "arrow"}/>
                <div className="box">
                    <h1>Testing, Launch & Maintenance</h1>
                    <ul>
                        <li>Test for bugs, broken links, and usability issues.</li>
                        <li>Conduct performance and security checks.</li>
                        <li>Launch the website once everything is approved.</li>
                        <li>Provide ongoing updates, maintenance, and performance tracking.</li>
                    </ul>
                    {/* <button>Go</button> */}
                </div>
            </div>
        </section>
    )
}