import { motion } from "framer-motion"

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import "./Awards.css"

// import client1 from "../../../Assets/Clients/client1.webp"
// import client2 from "../../../Assets/Clients/client2.webp"
// import client3 from "../../../Assets/Clients/client3.png"
// import client4 from "../../../Assets/Clients/client4.webp"
// import client5 from "../../../Assets/Clients/client5.png"
// import client6 from "../../../Assets/Clients/client6.png"
// import client7 from "../../../Assets/Clients/client7.png"
// import client8 from "../../../Assets/Clients/client8.png"
// import client9 from "../../../Assets/Clients/client9.png"
// import client10 from "../../../Assets/Clients/client10.png"
// import client11 from "../../../Assets/Clients/client11.webp"
// import client12 from "../../../Assets/Clients/client12.png"
// import client13 from "../../../Assets/Clients/client13.png"
// import client14 from "../../../Assets/Clients/client14.webp"
// import client15 from "../../../Assets/Clients/client15.webp"
// import client16 from "../../../Assets/Clients/client16.png"

// import award1 from "../../../Assets/Awards/1.png"
import award2 from "../../../Assets/Awards/2.webp"
import award3 from "../../../Assets/Awards/3.webp"
import award4 from "../../../Assets/Awards/4.webp"
import award5 from "../../../Assets/Awards/5.webp"
import award6 from "../../../Assets/Awards/6.webp"
import award7 from "../../../Assets/Awards/7.webp"

// const data1 = [
//   {
//     img: client1,
//   },
//   {
//     img: client2,
//   },
//   {
//     img: client3,
//   },
//   {
//     img: client4,
//   },
//   // {
//   //   img: client5,
//   // },
//   // {
//   //   img: client6,
//   // },
//   {
//     img: client7,
//   },
//   // {
//   //   img: client8,
//   // },
//   {
//     img: client9,
//   },
//   {
//     img: client10,
//   },
//   {
//     img: client11,
//   },
//   {
//     img: client12,
//   },
//   {
//     img: client13,
//   },
//   // {
//   //   img: client14,
//   // },
//   {
//     img: client15,
//   },
//   {
//     img: client16,
//   },
// ];

// const gridContainerVariants = {
//   hidden: {
//       opacity: 0
//   },
//   show: {
//       opacity: 1,
//       transition: {
//           staggerChildren: .2,
//       }
//   }
// }

// const gridSquareVariants = {
//   hidden: {
//       opacity: 0,
//       y: 20,
//   },
//   show: {
//       opacity: 1,
//       transition: {
//           y: 0,
//           duration: 0.5,
//       }
//   }
// }

const data = [
  // {
  //   img: award1,
  //   link: "https://www.upwork.com/agencies/734510642592346112/"
  // },
  {
    img: award2,
    link: "https://www.designrush.com/agency/profile/rabbit-and-carrot"
  },
  {
    img: award3,
    link: "https://clutch.co/profile/rabbit-carrot"
  },
  {
    img: award4,
    link: "https://clutch.co/profile/rabbit-carrot"
  },
  {
    img: award5,
    link: "https://www.bark.com/en/gb/company/rabbit-and-carrot-llc/2MdZg9"
  },
  {
    img: award6,
    link: "https://www.yelp.com/biz/rabbit-and-carrot-wayne"
  },
  {
    img: award7,
    link: "https://www.goodfirms.co/company/rabbit-and-carrot-llc"
  }
]

const imageVariants = {
  hover: {
    scale: 1.05, // Slightly increase the size on hover
    transition: {
      duration: 0.3,
    },
  },
  initial: {
    x: 200,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      // repeat: Infinity,
      ease: "easeInOut",
    },
  },
  exit: {
    x: [0, 10, 0], // Small up and down movement
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut",
    },
  }
};

function Awards() {
    // function SampleNextArrow(props) {
    //     const { className, onClick } = props;
    //         return (
    //             <div
    //                 className={`${className} custom-arrow next-arrow`} 
    //                 // style={{ ...style, display: "block", background: "#BE3030" }}
    //                 onClick={onClick}
    //             />
    //         );
    // }
        
    // function SamplePrevArrow(props) {
    //     const { className, onClick } = props;
    //         return (
    //             <div
    //                 className={`${className} custom-arrow prev-arrow`}
    //                 // style={{ ...style, display: "block", background: "#BE3030" }}
    //                 onClick={onClick}
    //             />
    //         );
    // }
    // const settings = {
    //     dots: true,
    //     infinite: false,
    //     // speed: 500,
    //     slidesToShow: 4,
    //     slidesToScroll: 2,
    //     initialSlide: 0,
    //     autoplay: true,
    //     speed: 2000,
    //     autoplaySpeed: 2000,
    //     cssEase: "linear",
    //     pauseOnHover: true,
    //     nextArrow: <SampleNextArrow />,
    //     prevArrow: <SamplePrevArrow />,
    //     responsive: [
    //         {
    //         breakpoint: 1024,
    //         settings: {
    //             slidesToShow: 3,
    //             slidesToScroll: 3,
    //             infinite: true,
    //             dots: true
    //         }
    //         },
    //         {
    //         breakpoint: 600,
    //         settings: {
    //             slidesToShow: 2,
    //             slidesToScroll: 2,
    //             initialSlide: 2
    //         }
    //         },
    //         {
    //         breakpoint: 480,
    //         settings: {
    //             slidesToShow: 1,
    //             slidesToScroll: 1,
    //             dots: false
    //         }
    //         }
    //     ]
    // };
    return (
      <section className="container1 client">
          <h1 className="commonHeading"><span>Awards & Recognitions</span></h1>
              <div 
                // variants={gridContainerVariants}
                // initial="hidden"
                // whileInView="show"
                // viewport={{ once: false, amount: 0.5 }}
                className="client-body">
                  {/* <Slider {...settings}> */}
                      {data.map((d, i) => (
                          <motion.div 
                            // variants={gridSquareVariants}
                            // viewport={{ once: false, amount: 0.5 }}
                            key={d.img} 
                            className="client-image"
                            variants={imageVariants}
                            initial="initial"
                            whileInView="animate"
                            exit="exit"
                            whileHover="hover"
                            >
                              {/* <a href={d.link}> */}
                                <img src={d.img} alt="" className=""/>
                              {/* </a> */}
                          </motion.div>
                      ))} 
                  {/* </Slider> */}
              </div>
      </section>
    );
}

export default Awards;