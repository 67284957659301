import React from 'react'
import { motion } from "framer-motion"
import { FaRightLong, FaLeftLong } from "react-icons/fa6";

import logo from "../../../Assets//Logo/fliplogo.gif"
// import logoName from "../../../Assets/Logo/RNC-Logo-black.png"
import clutch from "../../../Assets/Common/clutch-logo.webp"
// import mail_icon from "../../../Assets/Common/mail-white.svg"
import phone_icon from "../../../Assets/Common/phone.svg"
// import left from "../../../Assets/Common/arrow-left1.png"
// import right from "../../../Assets/Common/arrow-right1.png"

// import facebook from "../../../Assets/Social-Links/facebook.svg"
// import instagram from "../../../Assets/Social-Links/instagram.svg"
// import youtube from "../../../Assets/Social-Links/youtube.svg"
// import twitter from "../../../Assets/Social-Links/twitter.svg"
// import linkedin from "../../../Assets/Social-Links/linkedin.svg"
// import mail_icon from "../../../Assets/Common/mail.svg"
// import phone_icon from "../../../Assets/Common/phone.svg"
// import SkyLineDubai from "../../Assets/Common/SkylineDubai.jpg"

import "./Footer.css"

export default function Footer() {
  return (
    <footer className="footer">
      <hr/>
      <div className="footer-top">
        <div className="row">
          <div className="col rnc-details-col">
            <img src={logo} alt="" className="logo"/>
            <p className='logo-details'>
            We’re all about creating communications that sparkle and shine for the brands we love. 
            But there’s more to us than just creating pretty pictures.</p>
            <ul className="logo-contact">
              {/* <a href="/"><li><img className="mail-icon" src={mail_icon} alt=""/>info@rabbitandcarrot.com<br/></li></a> */}
              <a href="tel:+971542226464"><li><img className="phone-icon" src={phone_icon} alt=""/>+971 542226464</li></a>
              {/* <a href="https://clutch.co/profile/rabbit-carrot"> */}
              <li><div className="clutch-review">
                <div className="left">
                  REVIEWED ON
                  <img src={clutch} alt="" />
                </div>
                <div className="right">
                <div className="rating">
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                  </div>
                  5.0 RATING
                </div>
              </div></li>
              {/* </a> */}
            </ul>
          </div>
          <div className="col">
            <h3>SERVICES <div className="underline"><span></span></div></h3>
            <ul>
              <li>Custom Website Development</li>
              <li>Ecommerce Development</li>
              <li>CMS Development</li>
              <li>Front-End Development</li>
              <li>Back-End Development</li>
              <li>Web Application Development</li>
              <li>Responsive Design and Development</li>
              <li>Website Maintenance & Support</li>
              <li>API Integration</li>
              <li>Performance Optimization</li>
              {/* <li>Explainer Videos</li>
              <li>3D Explainer Videos</li>
              <li>Story Telling Videos</li>
              <li>Educational Videos</li>
              <li>Trade Show Videos</li>
              <li>Live Action Videos</li>
              <li>Social Video Ads</li>
              <li>Video Case Studies</li>
              <li>Video Courses</li>
              <li>Brand Videos</li>
              <li>Video Retainer</li> */}
            </ul>
          </div>
          <div className="col">
            <h3>VIDEO STYLES<div className="underline"><span></span></div></h3>
            <ul>
              <li>2D Animation</li>
              <li>3D Animation</li>
              <li>Live Action Video</li>
              <li>Motion Graphics</li>
              <li>White Board Animaton</li>
              <li>Traditional Animation</li>
              <li>Vector Animation</li>
              {/* <li>Virtual Reality (VR) Animation</li>
              <li>Special Effects Animation (VFX)</li> */}
            </ul>
          </div>
          <div className="col">
            <h3>USE CASES<div className="underline"><span></span></div></h3>
            <ul>
            <li>Business Websites</li>
            <li>E-Commerce Platforms</li>
            {/* <li>Web Portals</li> */}
            <li>Booking and Reservation Systems</li>
            <li>Learning Management Systems (LMS)</li>
            <li>SaaS Applications</li>
            <li>Blogs and Content Platforms</li>
            <li>Portfolio Websites</li>
            <li>Social Media Platforms</li>
            <li>Marketplace Development</li>
            {/* <li>Membership and Subscription Sites</li> */}
            {/* <li>Event Management Systems</li> */}
              {/* <li>Culture Video</li>
              <li>Email Marketing Video</li>
              <li>Explainer Video</li>
              <li>Homepage Video</li>
              <li>Landing Page Video</li>
              <li>Sales Enablement Video</li>
              <li>Social Ad Video</li>
              <li>Thought Leadership Video</li> */}
            </ul>
          </div>
          <div className="col">
            <h3>INDUSTRIES <div className="underline"><span></span></div></h3>
            <ul>
              <li>B2B Video</li>
              <li>B2C Video</li>
              <li>NFP Video</li>
              <li>Technology</li>
              <li>Education</li>
              <li>Health Care</li>
              <li>Travel & Tourism</li>
              <li>Automotive</li>
              <li>Communication</li>
            </ul>
          </div>
        </div>
        {/* <img src={logo} alt=""/>
        <p className="details">We’re all about creating communications that sparkle and shine for the brands we love.
        But there’s more to us than just creating pretty pictures.</p> */}
        <div className="chat-on">
            <p className="whatsapp"><a href="https://wa.me/+971542226464">WHATSAPP</a></p>
            <p>
              <div className="chat">
              <motion.div
                initial= {{
                  opacity: 0
                }}
                animate= {{
                  opacity: 1,
                  x: -10,
                  transition: {
                    duration: 1.5,
                    repeat: Infinity
                  }
                }}><FaLeftLong size={"25px"}/>
                </motion.div>
              CHAT ON 
              <motion.div
                initial= {{
                  opacity: 0
                }}
                animate= {{
                  opacity: 1,
                  x: 10,
                  transition: {
                    duration: 1.5,
                    repeat: Infinity
                  }
                }}><FaRightLong size={"25px"}/>
                </motion.div>
              </div>
              </p>
            <p className="live-chat"><a href="#about-contact">GET IN TOUCH</a></p>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="copyRight">Rabbit And Carrot © 2009 - 2024. All Rights Reserved.</p>
        {/* <p className="policy">Terms of Use | Privacy Policy | Cookie Policy</p> */}
      </div>
    </footer>
  )
}