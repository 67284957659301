import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { AnimatePresence, motion } from 'framer-motion'
import { ImEnlarge } from "react-icons/im";

import FullScreenImage from './FullScreenImage'
import "./Gallary.css"

import play from "../../../Assets/Common/play-button.svg"
import closeButton from "../../../Assets/Common/close-button.svg"

import web1 from "../../../Assets/Works/web/1.webp"
import web2 from "../../../Assets/Works/web/2.webp"
import web3 from "../../../Assets/Works/web/3.webp"
import web4 from "../../../Assets/Works/web/4.webp"
import web5 from "../../../Assets/Works/web/5.webp"
import web6 from "../../../Assets/Works/web/6.webp"
import web7 from "../../../Assets/Works/web/7.webp"
import web8 from "../../../Assets/Works/web/8.webp"
import web9 from "../../../Assets/Works/web/9.webp"
import web10 from "../../../Assets/Works/web/10.webp"
import web11 from "../../../Assets/Works/web/11.webp"
import web12 from "../../../Assets/Works/web/12.webp"

const gallaryVariants = {
  initial: { y: 200, opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
  exit: { y: -200, opacity: 0, transition: { duration: .5 } },
  // initial1: { x: -100, opacity: 0 },
  // animate1: { x: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
  // exit1: { x: -100, opacity: 0, transition: { duration: .5 } },
};

export default function Gallary() {
    // const [playingVideo, setPlayingVideo] = useState(null);

    // const handleVideoClick = (videoUrl) => {
    //     setPlayingVideo(videoUrl);
    //   };

    const [playingImage, setPlayingImage] = useState(null);

    const handleImageClick = (imageUrl) => {
        setPlayingImage(imageUrl);
      };

  return (
    <section id="portfolio" className="portfolio">
      <h1 className="commonHeading"><span>Portfolio</span></h1>

        <AnimatePresence mode="wait">
            <motion.div  
              initial="initial"
              whileInView="animate"
              transition={{
                  duration: 1,
                  ease: "easeOut",
                  delay: 0.2
              }}
              exit="exit"
              viewport={{ once: false, amount: 0.5 }}
              variants={gallaryVariants}
              className='image-grid'>
              <div className="image-grid-item" onClick={() => handleImageClick(web1)}>
                  <img src={web1} alt="" className="web-img"></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <ImEnlarge />
                        <p className="company-name">CASSIDY</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web2)}>
                  <img src={web2} alt="" className="web-img"></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <ImEnlarge />
                        <p className="company-name">PROMINA</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web3)}>
                  <img src={web3} alt="" className="web-img"></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <ImEnlarge />
                        <p className="company-name">DENTAL</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web4)}>
                  <img src={web4} alt="" className="web-img"></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <ImEnlarge />
                        <p className="company-name">DIET BOWL</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web5)}>
                  <img src={web5} alt="" className="web-img"></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <ImEnlarge />
                        <p className="company-name">ZEBPAY</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web6)}>
                  <img src={web6} alt="" className="web-img"></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <ImEnlarge />
                        <p className="company-name">IDENTITY</p>
                      </div>
                  </div>
              </div>
            </motion.div>
            <motion.div  
              initial="initial"
              whileInView="animate"
              transition={{
                  duration: 1,
                  ease: "easeOut",
                  delay: 0.2
              }}
              exit="exit"
              viewport={{ once: false, amount: 0.5 }}
              variants={gallaryVariants}
              className='image-grid'>
              <div className="image-grid-item" onClick={() => handleImageClick(web7)}>
                  <img src={web7} alt=""></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <ImEnlarge />
                        <p className="company-name">ABU STUDIO</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web8)}>
                  <img src={web8} alt=""></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <ImEnlarge />
                        <p className="company-name">CLOTHY</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web9)}>
                  <img src={web9} alt=""></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <ImEnlarge />
                        <p className="company-name">DENTAL</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web10)}>
                  <img src={web10} alt=""></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <ImEnlarge />
                        <p className="company-name">VOYAGE</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web11)}>
                  <img src={web11} alt=""></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <ImEnlarge />
                        <p className="company-name">TIKI</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web12)}>
                  <img src={web12} alt=""></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <ImEnlarge />
                        <p className="company-name">GREEN DAY</p>
                      </div>
                  </div>
              </div>
            </motion.div>
          </AnimatePresence>
{/* <       AnimatePresence mode="wait"> */}
          {/* <motion.div
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants} */}
            <div
              className="button-container content-center">
            <a href="#about-contact"><button className="btn button-touch2"> Explore More
                    <span className="line">
                        <span className="circle"></span>
                    </span>
            </button></a>
            </div>
          {/* </motion.div> */}
          {/* </AnimatePresence> */}
    
    {/* {playingVideo && (
      <div className="video-overlay">
        <div className="video-player">
          <ReactPlayer className="player" url={playingVideo} playing={true} controls width="100%" height="100%" />
        </div>
          <img className="close-button1" onClick={() => setPlayingVideo(null)} src={closeButton}/>
        <img className="close-button1" src={closeButton} alt=""/>
      </div>
      )} */}

    {playingImage && (
      <div className="video-overlay">
        <div className="video-player">
          <FullScreenImage src={playingImage} />
      </div>
        <img className="close-button1" alt="Close Button" onClick={() => setPlayingImage(null)} src={closeButton}/>
      </div>
    )}
  </section>
  )
}